/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// ComparisonBarChart configurations
import configs from "components/Advanced/Charts/BarCharts/ComparisonBarChart/configs";

import { Chart as ChartJS, registerables } from "chart.js";
import ListOptions from "components/Custom/ListOptions";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";

ChartJS.register(...registerables);

function ComparisonBarChart({
	icon = { color: "info", component: "" },
	title = "",
	description = "",
	height = "19.125rem",
	chart,
	data: chartData
}) {
	const { editMode = false } = chartData;

	const [mode, setMode] = useState(chartData.mode);
	const [datasets, setDatasets] = useState(chartData.datasets);

	const { data, options } = configs(chartData.labels || [], datasets);

	const handleChangeDatasetMode = mode => {
		const newDatasets = datasets.map(dataset => {
			switch (mode) {
				case "raw":
					setMode("raw");
					return {
						...dataset,
						data: dataset.dataTotal
					};
				case "percentage":
					setMode("percentage");
					return {
						...dataset,
						data: dataset.dataPercentage
					};
				default:
					return dataset;
			}
		});
		setDatasets(newDatasets);
	};

	const renderChart = (
		<MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
			<MDBox
				display="flex"
				justifyContent="space-between"
				px={description ? 1 : 0}
				pt={description ? 1 : 0}
			>
				<MDBox display="flex">
					{icon.component && (
						<MDBox
							width="4rem"
							height="4rem"
							bgColor={icon.color || "info"}
							variant="gradient"
							coloredShadow={icon.color || "info"}
							borderRadius="xl"
							display="flex"
							justifyContent="center"
							alignItems="center"
							color="white"
							mt={-5}
							mr={2}
						>
							<Icon fontSize="medium">{icon.component}</Icon>
						</MDBox>
					)}
					<MDBox mt={icon.component ? -2 : 0}>
						{title && <MDTypography variant="h6">{title}</MDTypography>}
						<MDBox mb={2}>
							<MDTypography component="div" variant="button" color="text">
								{description}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>

				{!editMode && (
					<MDBox mt={icon.component ? -2 : 0}>
						{/* Display mode */}
						<FormControl sx={{ width: "10vw" }}>
							<InputLabel id="select-label">{t("CHARTS.COMPARISON.display")}</InputLabel>
							<Select
								labelId="select-label"
								id="select"
								label={t("CHARTS.COMPARISON.display")}
								value={mode}
								onChange={e => handleChangeDatasetMode(e.target.value)}
							>
								<MenuItem value="raw">{t("CHARTS.COMPARISON.average")}</MenuItem>
								<MenuItem value="percentage">{t("CHARTS.COMPARISON.percentage")}</MenuItem>
							</Select>
						</FormControl>
						{/* Menu Options */}
						<ListOptions chart={chart} options={chartData.options ?? {}} />
					</MDBox>
				)}
			</MDBox>
			<MDBox height="100%">
				<Bar data={data} options={options} />
			</MDBox>
		</MDBox>
	);

	return <Card>{renderChart}</Card>;
}

export default ComparisonBarChart;
