/* eslint-disable prefer-destructuring */
import lod_ from "lodash";
import { Card, Divider, Icon, Table, TableCell, TableRow, Tooltip } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBadge from "components/Basics/MDBadge";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import StarRating from "components/Custom/StarRating/StarRating";

/**
 * Review star progress bar
 * @param {props.value} value the value of the progress bar (0-100)
 * @param {props.rawValue} rawValue the raw value of the progress bar
 * @returns
 */
const StarRowProgressBar = ({ value, rawValue }) => {
	return (
		<Tooltip title={`${rawValue} Avis`} placement="left">
			{/* Shadow box */}
			<MDBox
				style={{
					borderRadius: "100px"
				}}
				shadow="xs"
			>
				{/* Background box */}
				<MDBox
					style={{
						backgroundColor: colors.reviews.neutral,
						overflow: "hidden",
						borderRadius: "100px"
					}}
				>
					{/* Progress bar */}
					<MDBox
						style={{
							backgroundColor: colors.reviews.star,
							borderRadius: "100px"
						}}
						width={`${value}%`}
						height="1rem"
					></MDBox>
				</MDBox>
			</MDBox>
		</Tooltip>
	);
};

/**
 * Review star row
 * @param {props.rating} rating the rating of the row
 * @param {props.value} value the value of the row
 * @param {props.total} total the total number of reviews
 * @returns
 */
const StarRow = ({ rating, value, total }) => {
	// Calculate percentage
	const percentage = total > 0 ? (value / total) * 100 : 0;

	return (
		<TableRow>
			<TableCell style={{ paddingRight: "0.5rem", border: "none" }} sx={{ p: 0, m: 0 }}>
				<MDTypography
					variant="body2"
					style={{
						fontWeight: "bold"
					}}
				>
					{rating}
				</MDTypography>
			</TableCell>
			<TableCell style={{ width: "100%", border: "none" }} sx={{ p: 0, m: 0 }}>
				<StarRowProgressBar value={percentage} rawValue={value} />
			</TableCell>
		</TableRow>
	);
};

/**
 * Display large number (adding space every 3 digits)
 * @param {number} number
 */
const displayLargeNumber = number => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

/**
 * Review header chart
 * @param {*} param0
 * @returns
 */
const ReviewHeaderChart = ({ chart, data = {} }) => {
	if (!data.success) {
		return <Card>Impossible de charger les données</Card>;
	}

	let style = {};

	if (!data.target) {
		style = {
			filter: "blur(5px)"
		};
	}

	const reviewData = data.data ?? {};
	const total = reviewData.total ?? 0;

	return (
		<Card>
			{!data.target && (
				<MDBox
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						zIndex: 1
					}}
				>
					<MDTypography variant="h5" align="center">
						Sélectionnez un magasin pour afficher les avis
					</MDTypography>
				</MDBox>
			)}
			<MDBox
				style={style}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				pt={1}
				px={2}
				sx={{ minHeight: "20vh" }}
			>
				{/* Total */}
				<MDBox flex="1" display="flex" flexDirection="column">
					<MDBox
						m={3}
						flex="1"
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
					>
						<MDBox flex="1">
							<MDTypography variant="body1">{`Nombre d'avis`}</MDTypography>
							<MDTypography variant="h3">{displayLargeNumber(total)}</MDTypography>
						</MDBox>

						{!lod_.isNil(reviewData.previousTotalDifference) && (
							<MDBox>
								<MDBadge
									badgeContent={`${reviewData.previousTotalDifference > 0 ? "+" : ""}${reviewData.previousTotalDifference}`}
									variant="contained"
									color={reviewData.previousTotalDifference > 0 ? "success" : "error"}
									circular
									container
								/>
								<MDTypography ml={0.5} variant="caption">
									Par rapport à la période précédente
								</MDTypography>
							</MDBox>
						)}
					</MDBox>
				</MDBox>
				{/*  */}
				<MDBox display="flex">
					<Divider
						orientation="vertical"
						variant="middle"
						flexItem
						style={{
							height: "100%"
						}}
					/>
				</MDBox>
				{/* Average */}
				<MDBox flex="1" display="flex" flexDirection="column">
					<MDBox
						m={3}
						flex="1"
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
					>
						<MDBox flex="1">
							<MDTypography variant="body1">Note moyenne</MDTypography>
							<MDBox display="flex" flexDirection="row" alignItems="center">
								<MDTypography variant="h3">{reviewData.avg ?? 0}</MDTypography>
								<StarRating rating={reviewData.avg} max={5} />
							</MDBox>
						</MDBox>

						{!lod_.isNil(reviewData.previousAverageDifference) && (
							<MDBox>
								<MDBadge
									badgeContent={`${reviewData.previousAverageDifference > 0 ? "+" : ""}${reviewData.previousAverageDifference}`}
									variant="contained"
									color={reviewData.previousAverageDifference > 0 ? "success" : "error"}
									circular
									container
								/>
								<MDTypography ml={0.5} variant="caption">
									Par rapport à la période précédente
								</MDTypography>
							</MDBox>
						)}
					</MDBox>
				</MDBox>
				{/*  */}
				<MDBox display="flex">
					<Divider orientation="vertical" variant="middle" flexItem />
				</MDBox>
				{/* Rank */}
				<MDBox flex="1">
					<MDBox m={3}>
						<Table style={{ width: "100%" }}>
							<StarRow rating={5} value={reviewData["5"] ?? 0} total={total} />
							<StarRow rating={4} value={reviewData["4"] ?? 0} total={total} />
							<StarRow rating={3} value={reviewData["3"] ?? 0} total={total} />
							<StarRow rating={2} value={reviewData["2"] ?? 0} total={total} />
							<StarRow rating={1} value={reviewData["1"] ?? 0} total={total} />
						</Table>
					</MDBox>
				</MDBox>
			</MDBox>
		</Card>
	);
};

export default ReviewHeaderChart;
