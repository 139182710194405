/* eslint-disable no-plusplus */
import colors from "assets/theme/base/colors";
import "./StarRating.css";
import { Star } from "@mui/icons-material";

/**
 * Custom star component
 * @param {*} props
 * @returns
 */
const CustomStar = ({ decimal }) => {
	if (decimal > 1) {
		decimal = 1;
	}
	if (decimal < 0) {
		decimal = 0;
	}
	return (
		<div className="stars-container">
			<div className="stars-row-container">
				<Star
					className="star-base"
					style={{
						color: colors.reviews.star,
						clipPath: "inset(0 " + (1 - decimal) * 100 + "% 0 0)"
					}}
				></Star>
			</div>

			<div className="stars-row-container">
				<Star
					className="star-base"
					style={{
						color: "lightGrey",
						clipPath: "inset(0 0 0 " + decimal * 100 + "%)"
					}}
				></Star>
			</div>
		</div>
	);
};

/**
 * Star rating component
 * @param {props.rating} number rating value
 * @param {props.max} number max rating value
 * @param {props.withText} boolean display rating value
 * @returns
 */
function StarRating({ rating, max = 5, withText = false }) {
	if (max !== 5) {
		rating = (rating * 5) / max;
		max = 5;
	}

	function loopStars() {
		let stars = [];
		let note = rating;
		for (let i = 0; i < max; i++) {
			stars.push(<CustomStar key={i} decimal={note}></CustomStar>);
			note--;
		}
		return stars;
	}

	return (
		<div className="stars">
			{loopStars()}
			{withText && (
				<div className="writtedNote">
					({Math.round(rating * 100) / 100}/{max})
				</div>
			)}
		</div>
	);
}

export default StarRating;
