/* eslint-disable prefer-destructuring */
/* eslint-disable no-unreachable */
import { Fade, Grid, Skeleton, Typography } from "@mui/material";
import ComparisonBarChart from "components/Advanced/Charts/BarCharts/ComparisonBarChart";
import { LoadingComplexStatisticsCard } from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/Basics/MDBox";
import ComparisonCrossTable from "../comparisonCrossTable";
import ComparisonReviewHeatmap from "../comparisonReviewHeatmap";

const ComparisonChart = ({ chart, data, ...rest }) => {
	function getCorrectGraph(chart, data) {
		switch (chart.display.subType) {
			case "table":
			case "sortedList":
				return <ComparisonCrossTable chart={chart} data={data} />;
			case "bar":
				return (
					<ComparisonBarChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chart={chart}
						data={data}
					/>
				);
			case "reviewHeatmap":
				return <ComparisonReviewHeatmap chart={chart} data={data} />;
			default:
				return <Typography>{`${chart.display.subType} Not implemented`}</Typography>;
		}
	}

	if (!chart.loaded) {
		return (
			<Fade in={!chart.loaded}>
				<Grid item {...rest} sx={{ m: 0, p: 0 }}>
					<MDBox py={2} pr={2} pl={chart.display.icon.component ? 1 : 2}>
						<Skeleton variant="rounded" height={400} />
					</MDBox>
				</Grid>
			</Fade>
		);
	} else if (!data) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text={data?.length === 0 ? "Aucune donnée" : "Chargement des données"}
					/>
				</MDBox>
			</Grid>
		);
	} else {
		return (
			<Grid item {...rest}>
				{getCorrectGraph(chart, data)}
			</Grid>
		);
	}
};

export default ComparisonChart;
