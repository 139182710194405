/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import InformationCard from "pages/settings/charts/steps/components/InformationCard";
import { useEffect, useState } from "react";
import ChartsActions from "redux-react/actions/chartsActions";
import { useDispatch, useSelector } from "react-redux";
import csvDownload from "json-to-csv-export";

const {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress
} = require("@mui/material");

function parseCSV(data) {
	if (data.datasets) {
		let dataset = data.datasets[0];
		let res = [];
		for (let i = 0; i < dataset.data.length; i++) {
			res.push({
				label: data.labels[i],
				value: dataset.data[i]
			});
		}

		return {
			data: res,
			headers: [
				{
					key: "label",
					label: "Label"
				},
				{
					key: "value",
					label: "Valeur"
				}
			]
		};
	} else if (data.rows && data.columns) {
		if (data.totalRow) {
			data.rows.push(data.totalRow);
		}
		let res = [];
		let headers = [];
		data.columns.map((item, colIndex) => {
			headers.push({
				key: item.accessor,
				label: item.Header
			});

			data.rows.map((row, index) => {
				let exist = true;
				let obj = res.find((r, i) => i === index);

				if (!obj) {
					obj = {};
					exist = false;
				}
				let value = row[item.accessor] ?? 0;
				obj[item.accessor] = value;

				if (!exist) {
					res.push(obj);
				}
			});
		});

		return {
			data: res,
			headers
		};
	} else {
		try {
			let keys = Object.keys(data[0]);
			if (keys.includes("_id")) {
				keys = keys.filter(key => key !== "_id");
				data = data.map(item => {
					delete item._id;
					return item;
				});
			}
			return {
				data,
				headers: keys
			};
		} catch (e) {
			return {
				data: [],
				headers: []
			};
		}
	}
}

const getUserLabel = (profile, label) => {
	let trad = label[profile.language];
	if (!trad) {
		let firstLabel = Object.keys(label)[0];
		trad = label[firstLabel];
	}
	return trad;
};

const DownloadList = ({ open, onClose: onCloseProps, chart, data = [], title }) => {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);

	const [openDialog, setOpenDialog] = useState(false);

	const [selection, setSelection] = useState("screen");
	const [totalCount, setTotalCount] = useState(0);
	const [fileName, setFileName] = useState(title);
	const [loading, setLoading] = useState(true);

	let actualListLength = data?.length ?? 0;

	let dictionary = chart.dictionary ?? {};
	let possibleCollection = dictionary[chart?.request?.collection];

	const getCompleteList = async () => {
		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getListByCode(profile.assistantID, chart.code, res => {
					resolve(res.paginedList?.data?.rows ?? []);
				})
			);
		});
	};

	const onClose = () => {
		setOpenDialog(false);
		onCloseProps();
	};

	const downloadSelection = async (dataset = data) => {
		let currentData = [];

		switch (selection) {
			case "screen":
				currentData = dataset;
				break;
			case "all":
				currentData = await getCompleteList();
				break;
			default:
				break;
		}

		let csv = parseCSV(currentData);
		csvDownload({
			data: csv.data,
			filename: `${fileName}.csv`,
			headers: csv.headers,
			delimiter: ";"
		});
		onClose();
	};

	useEffect(() => {
		if (open) {
			if (chart.type === "paginedList") {
				if (possibleCollection) {
					setFileName(getUserLabel(profile, possibleCollection.label));
				}

				dispatch(
					ChartsActions.getListCount(
						profile.assistantID,
						chart.request.collection,
						chart.filters,
						res => {
							setTotalCount(res.count);
							setLoading(false);
						}
					)
				);
			} else {
				onCloseProps();
				setSelection("screen");
				if (lod_.isArray(data)) {
					data.map(el => {
						delete el.color;
						delete el.label;
						if (el.name === "null_attribute") {
							delete el.name;
						}
						return el;
					});
				}
				downloadSelection(data);
			}
		}

		return () => {
			setTotalCount(0);
			setSelection(null);
			setFileName("");
			setLoading(true);
		};
	}, [open]);

	return (
		<Dialog open={openDialog} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>{`Télécharger la liste ${possibleCollection ? `"${getUserLabel(profile, possibleCollection.label)}"` : ""}`}</DialogTitle>
			<DialogContent>
				{loading ? (
					<MDBox display="flex" justifyContent="center" alignItems="center">
						<CircularProgress color="info" />
					</MDBox>
				) : (
					<MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
						<MDBox
							style={{
								width: "100%"
							}}
						>
							<InformationCard
								checkbox
								element={{
									title: "Télécharger la selection",
									description: `Télécharger les ${actualListLength} éléments à l'écran`
								}}
								onClick={() => setSelection("screen")}
								selected={selection === "screen"}
							/>
						</MDBox>
						<MDBox
							style={{
								width: "100%"
							}}
						>
							<InformationCard
								checkbox
								element={{
									title: "Télécharger toute la liste",
									description: `Télécharger les ${totalCount} éléments de la liste`
								}}
								onClick={() => setSelection("all")}
								selected={selection === "all"}
							/>
						</MDBox>
					</MDBox>
				)}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					Fermer
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={downloadSelection}
					disabled={!selection}
				>
					Télécharger
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default DownloadList;
