/* eslint-disable no-plusplus */
/* eslint-disable radix */
import React from "react";

export function regexPhoneNumber(str) {
	const regex = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;
	return regex.test(str.trim().toLowerCase());
}

export function regexMail(str) {
	/* eslint-disable-next-line */
	return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str.trim().toLowerCase());
}

export function generateRandomCode(length = 5) {
	let code = "";
	let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	for (let i = 0; i < length; i++) {
		code += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return code;
}

export function slugify(text) {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-") // Replace spaces with -
		.replace(/[^\w-]+/g, "") // Remove all non-word chars
		.replace(/--+/g, "-") // Replace multiple - with single -
		.replace(/^-+/, "") // Trim - from start of text
		.replace(/-+$/, ""); // Trim - from
}

export function generateNameCode(text) {
	return `${slugify(text)}-${generateRandomCode()}`;
}

/**
 * Convert the color name to hex
 * @param {string} colorName - The color name
 * @returns {string} - The hex color
 */
export function getHexColor(colorName) {
	if (!colorName) return "#000000";
	// Create a temporary element to get the computed color
	const tempElement = document.createElement("div");
	tempElement.style.color = colorName; // Assign the color to the temporary element
	document.body.appendChild(tempElement);

	// Get the computed color
	const computedColor = window.getComputedStyle(tempElement).color;
	document.body.removeChild(tempElement); // Clean up

	// Convert the computed color to hex
	const rgb = computedColor.match(/\d+/g); // Extract the RGB components
	const hex = `#${rgb.map(x => parseInt(x).toString(16).padStart(2, "0")).join("")}`;

	return hex;
}
