/* eslint-disable prefer-destructuring */
/* eslint-disable no-unreachable */
import { Card, Fade, Grid, Skeleton, Typography } from "@mui/material";
import { LoadingComplexStatisticsCard } from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import HorizontalBarChart from "components/Advanced/Charts/BarCharts/HorizontalBarChart";
import VerticalBarChart from "components/Advanced/Charts/BarCharts/VerticalBarChart";
import DefaultDoughnutChart from "components/Advanced/Charts/DoughnutCharts/DefaultDoughnutChart";
import PieChart from "components/Advanced/Charts/PieChart";
import MDBox from "components/Basics/MDBox";
import StackedChart from "components/Advanced/Charts/StackedChart";
import CrossTable from "../crossTable";

const ProportionChart = ({ chart, data, ...rest }) => {
	function getProporiton(item) {
		return item.proportion ?? item.total;
	}
	function mapData(chart, data) {
		let skeleton;

		switch (chart.display.subType) {
			case "pie":
			case "donut":
				skeleton = {
					labels: [],
					datasets: {
						label: chart.display.label ?? "",
						backgroundColors: [],
						data: []
					}
				};

				data.map(item => {
					skeleton.labels.push(item.label ?? item.name);
					skeleton.datasets.backgroundColors.push(item.color);
					skeleton.datasets.data.push(getProporiton(item));
				});

				skeleton.datasets.defaultData = data;
				skeleton.datasets.valueAfterPercentage = chart.display?.valueAfterPercentage ?? false;

				if (chart.display.datalabels) {
					skeleton.datasets.datalabels = {
						opacity: 1,
						color: "white",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						borderRadius: 4,
						padding: 4,
						formatter: (value, context) => {
							let currentData = data[context.dataIndex];
							let total = currentData.total;

							let label = value;

							if (context.dataset?.label) {
								label = `${value} ${context.dataset.label}`;
							}

							if (chart.display.valueAfterPercentage) {
								label = `${label} (${total})`;
							}

							return label;
						},
						anchor: "end",
						align: "end"
					};
				} else {
					skeleton.datasets.datalabels = {};
				}

				return skeleton;
			case "verticalBar":
			case "horizontalBar":
				skeleton = {
					options: chart.options,
					labels: [],
					datasets: [
						{
							label: chart.display.label ?? "",
							backgroundColor: [],
							data: []
						}
					]
				};

				if (data.datasets) {
					data.options = chart.options ?? {};
					return data;
				}

				data.map(item => {
					skeleton.datasets[0].backgroundColor.push(item.color);
					skeleton.labels.push(item.label ?? item.name);
					skeleton.datasets[0].data.push(getProporiton(item));
				});

				skeleton.datasets[0].defaultData = data;
				skeleton.datasets[0].valueAfterPercentage = chart.display?.valueAfterPercentage ?? false;

				if (chart.display.datalabels) {
					skeleton.datasets[0].datalabels = {
						opacity: 1,
						color: "white",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						borderRadius: 4,
						padding: 4,
						formatter: (value, context) => {
							let currentData = data[context.dataIndex];
							let total = currentData.total;

							let label = value;

							if (context.dataset?.label) {
								label = `${value} ${context.dataset.label}`;
							}

							if (chart.display.valueAfterPercentage) {
								label = `${label} (${total})`;
							}

							return label;
						}
					};
				} else {
					skeleton.datasets[0].datalabels = {};
				}
				skeleton.data = data;
				return skeleton;
			default:
				return data;
		}
	}

	function getCorrectGraph(chart, data) {
		switch (chart.display.subType) {
			case "table":
			case "sortedList":
				return <CrossTable chart={chart} data={data} />;
			case "pie":
				return (
					<PieChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chartData={mapData(chart, data)}
						chart={chart}
					/>
				);
			case "donut":
				return (
					<DefaultDoughnutChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chartData={mapData(chart, data)}
						chart={chart}
					/>
				);
			case "verticalBar":
				return (
					<VerticalBarChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chartData={mapData(chart, data)}
						chart={chart}
					/>
				);
			case "horizontalBar":
				return (
					<HorizontalBarChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chartData={mapData(chart, data)}
						chart={chart}
					/>
				);
			case "stackedBar":
				return (
					<StackedChart
						icon={chart.display.icon}
						title={chart.display.title}
						description={chart.display.description}
						chartData={mapData(chart, data)}
						chart={chart}
					/>
				);
			default:
				return <Typography>{`${chart.display.subType} Not implemented`}</Typography>;
		}
	}

	if (!chart.loaded) {
		return (
			<Fade in={!chart.loaded}>
				<Grid item {...rest} sx={{ m: 0, p: 0 }}>
					<MDBox py={2} pr={2} pl={chart.display.icon.component ? 1 : 2}>
						<Skeleton variant="rounded" height={400} />
					</MDBox>
				</Grid>
			</Fade>
		);
	} else if (!data) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text={data?.length === 0 ? "Aucune donnée" : "Chargement des données"}
					/>
				</MDBox>
			</Grid>
		);
	} else {
		return (
			<Grid item {...rest}>
				{getCorrectGraph(chart, data)}
			</Grid>
		);
	}
};

export default ProportionChart;
