/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

const { gradients, dark } = colors;

function configs(labels, datasets, cutout = 60) {
	const backgroundColors = [];

	if (datasets.backgroundColors) {
		datasets.backgroundColors.forEach(color => {
			if (gradients[color]) {
				if (color === "info") {
					backgroundColors.push(gradients.info.main);
				} else {
					backgroundColors.push(gradients[color].state);
				}
			} else {
				backgroundColors.push(color);
			}
		});
	} else {
		backgroundColors.push(dark.main);
	}

	return {
		data: {
			labels,
			datasets: [
				{
					...datasets,
					weight: 9,
					cutout,
					tension: 0.9,
					pointRadius: 2,
					borderWidth: 2,
					backgroundColor: backgroundColors,
					fill: false
				}
			]
		},
		options: {
			layout: {
				padding: {
					top: 25,
					bottom: 25
				}
			},
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: datasets?.data?.length > 13 ? "bottom" : "left",

					labels: {
						usePointStyle: true, // Use custom point styles
						pointStyle: "rect", // Set the shape to a filled rectangle
						boxWidth: 20, // Ensure the square has the right width
						boxHeight: 20, // Set the height to match the width for a square
						padding: 8, // Add some padding between legend items
						font: {
							size: 13
						}
					}
				},
				tooltip: {
					callbacks: {
						label: tooltipItem => {
							const value = tooltipItem.formattedValue;
							const label = tooltipItem.dataset.label;

							let tooltipValue = value;

							// 1- Display label
							if (label) {
								if (label.trim() === "%") {
									tooltipValue = `${tooltipValue}${label}`;
								} else {
									tooltipValue = `${tooltipValue} ${label}`;
								}
							}

							// 2- Display raw value
							if (tooltipItem.dataset?.valueAfterPercentage) {
								let defaultData = tooltipItem.dataset.defaultData ?? [];
								let defaultValue = defaultData[tooltipItem.dataIndex];

								if (defaultValue) {
									let total = defaultValue.total;

									if (total !== undefined && total !== null) {
										tooltipValue = `${tooltipValue} (${total})`;
									}
								}
							}

							return tooltipValue;
						}
					}
				}
			},
			scales: {
				y: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					ticks: {
						display: false
					}
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					ticks: {
						display: false
					}
				}
			}
		}
	};
}

export default configs;
