import { useState } from "react";
import { Divider, Grid } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import ChartEditingOrder from "../Components/ChartEditingOrder";
import ReviewChart from ".";

/**
 * Display review charts
 * @param {*} param0
 * @returns
 */
const ReviewChartDisplay = ({
	pageEditMode,
	getChartsData,
	dragHandler,
	handleEditChart,
	handleDeleteChart
}) => {
	const [hoveredChart, setHoveredChart] = useState(null);

	const editingMouseInCard = code => {
		setHoveredChart(code);
	};

	const editingMouseOutCard = code => {
		setHoveredChart(null);
	};

	const isHovered = code => {
		return hoveredChart === code;
	};

	return (
		<>
			<MDBox display="flex" flexDirection="row">
				{pageEditMode && (
					<ChartEditingOrder
						getChartsData={getChartsData}
						type={["review"]}
						dragHandler={dragHandler}
						handleEditChart={handleEditChart}
						mouseIn={editingMouseInCard}
						mouseOut={editingMouseOutCard}
						handleDeleteChart={handleDeleteChart}
					/>
				)}
				<MDBox
					flex="5"
					style={{
						width: "100%",
						overflowX: "auto"
					}}
				>
					<Grid container spacing={3} columns={{ xs: 2, sm: 2, md: 4, xxl: 6 }}>
						{getChartsData(["review"]).map((chart, index) => {
							return (
								<ReviewChart
									id={chart.code}
									key={index}
									chart={chart}
									data={chart.data}
									mt={5}
									xs={2}
									md={4}
									lg={2}
									xxl={6}
									style={{
										filter: isHovered(chart.code) ? "brightness(0.8)" : ""
									}}
								/>
							);
						})}
					</Grid>
				</MDBox>
			</MDBox>
			{pageEditMode && getChartsData(["review"]).length > 0 && <Divider mt={10} mb={10} />}
		</>
	);
};

export default ReviewChartDisplay;
