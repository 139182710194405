/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function configs(labels, datasets, options) {
	let legendDisplay = options?.legend !== undefined ? options.legend : true;

	datasets.map(dataset => {
		dataset.backgroundColor = dataset.color;
		dataset.borderWidth = 0;
		delete dataset.maxBarThickness;
	});

	return {
		data: {
			labels,
			datasets: [...datasets]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: legendDisplay,
					position: datasets.length > 6 ? "bottom" : "left",

					labels: {
						usePointStyle: true, // Use custom point styles
						pointStyle: "rect", // Set the shape to a filled rectangle
						boxWidth: 20, // Ensure the square has the right width
						boxHeight: 20, // Set the height to match the width for a square
						padding: 10, // Add some padding between legend items
						font: {
							size: 12
						}
					}
				}
			},
			scales: {
				x: {
					gridLines: {
						display: false
					},
					barPercentage: 1,
					categoryPercentage: 1,
					stacked: true,
					ticks: {
						display: true,
						color: "#9ca2b7",
						font: {
							size: 11,
							style: "normal"
						}
					}
				},
				y: {
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5]
					},
					ticks: {
						display: true,
						padding: 10,
						color: "#b2b9bf",
						font: {
							size: 11,
							style: "normal"
						}
					},
					stacked: true
				}
			}
		}
	};
}

export default configs;
