/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function configs(labels, datasets) {
	return {
		data: {
			labels,
			datasets
		},
		options: {
			indexAxis: "y",
			// responsive: true,
			// maintainAspectRatio: false,
			plugins: {
				tooltip: {
					callbacks: {
						label: context => `${context.dataset.label}: ${Math.abs(context.raw)}` // Affiche les valeurs absolues dans l'infobulle
					}
				}
			},
			scales: {
				y: {
					stacked: true,
					grid: {
						drawBorder: false,
						display: true,
						drawOnChartArea: true,
						drawTicks: false,
						borderDash: [5, 5],
						color: "#c1c4ce5c"
					},
					ticks: {
						padding: 10,
						color: "#9ca2b7",
						font: {
							weight: 300,
							family: "Roboto",
							style: "normal"
						}
					}
				},
				x: {
					stacked: true,
					ticks: {
						stepSize: 500,
						callback: value => Math.abs(value), // Affiche des valeurs absolues sur l'axe
						color: "#9ca2b7",
						padding: 10,
						font: {
							weight: 300,
							family: "Roboto",
							style: "normal"
						}
					}
				}
			}
		}
	};
}

export default configs;
