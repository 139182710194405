/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect, useState, useMemo } from "react";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// DefaultLineChart configurations
import configs from "components/Advanced/Charts/LineCharts/DefaultLineChart/configs";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from "chart.js";
import ListOptions from "components/Custom/ListOptions";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function DefaultLineChart({
	icon = { color: "info", component: "" },
	title = "",
	description = "",
	height = "100%",
	chart,
	chartData: chartDataProps
}) {
	const { editMode = false } = chart;

	const chartRef = useRef(null);

	const [chartData, setChartData] = useState({ data: { labels: [], datasets: [] } });
	const { data, options } = chartData;

	const getBackgroundColor = color => {
		if (colors[color]) {
			return colors[color || "dark"].main;
		}
		if (typeof color !== "string") {
			return "#00d084";
		}
		return color;
	};

	useEffect(() => {
		const chartDatasets = (chartDataProps.datasets ?? []).map(dataset => ({
			...dataset,
			tension: 0.3,
			pointRadius: 2,
			borderWidth: 2,
			backgroundColor: "transparent",
			fill: true,
			pointBackgroundColor: getBackgroundColor(dataset.color),
			borderColor: getBackgroundColor(dataset.color),
			maxBarThickness: 3
		}));

		setChartData(configs(chartDataProps.labels || [], chartDatasets, chartDataProps.options));
	}, [chartDataProps]);

	const renderChart = (
		<MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
			<MDBox
				display="flex"
				justifyContent="space-between"
				px={description ? 1 : 0}
				pt={description ? 1 : 0}
			>
				<MDBox display="flex">
					{icon.component && (
						<MDBox
							width="4rem"
							height="4rem"
							bgColor={icon.color || "info"}
							variant="gradient"
							coloredShadow={icon.color || "info"}
							borderRadius="xl"
							display="flex"
							justifyContent="center"
							alignItems="center"
							color="white"
							mt={-5}
							mr={2}
						>
							<Icon fontSize="medium">{icon.component}</Icon>
						</MDBox>
					)}
					<MDBox mt={icon.component ? -2 : 0}>
						{title && <MDTypography variant="h6">{title}</MDTypography>}
						<MDBox mb={2}>
							<MDTypography component="div" variant="button" color="text">
								{description}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
				{!editMode && (
					<MDBox mt={icon.component ? -2 : 0}>
						<ListOptions chart={chart} options={{}} />
					</MDBox>
				)}
			</MDBox>
			{useMemo(
				() => (
					<MDBox ref={chartRef} sx={{ height }}>
						<Line data={data} options={options} />
					</MDBox>
				),
				[chartData, height]
			)}
		</MDBox>
	);

	return <Card>{renderChart}</Card>;
}

export default DefaultLineChart;
