/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */

import { LoadingComplexStatisticsCard } from "components/Advanced/Cards/StatisticsCards/ComplexStatisticsCard";
import ListOptions from "components/Custom/ListOptions";
import ComparisonReviewHeatmapTable from "components/Advanced/Tables/ComparisonReviewHeatmapTable";
import StackedChart from "components/Advanced/Charts/StackedChart";
import colors from "assets/theme/base/colors";

const { Card, Icon, Grid, Skeleton, Fade } = require("@mui/material");
const { default: MDBox } = require("components/Basics/MDBox");
const { default: MDTypography } = require("components/Basics/MDTypography");

/**
 * Comparison review heatmap chart
 * @param {*} param0
 * @returns
 */
function ComparisonReviewHeatmap({ chart, data, ...rest }) {
	const { editMode = false } = chart;

	/**
	 * Convert the initial datasets to a stacked chart datasets
	 * @param {Array} rows
	 * @returns
	 */
	const convertDatasetToStackedChart = rows => {
		let datasets = [
			{ label: "1", data: [], color: colors.reviews["1"] },
			{ label: "2", data: [], color: colors.reviews["2"] },
			{ label: "3", data: [], color: colors.reviews["3"] },
			{ label: "4", data: [], color: colors.reviews["4"] },
			{ label: "5", data: [], color: colors.reviews["5"] }
		];
		for (let row of rows) {
			datasets[0].data.push(row["1_percentage"]);
			datasets[1].data.push(row["2_percentage"]);
			datasets[2].data.push(row["3_percentage"]);
			datasets[3].data.push(row["4_percentage"]);
			datasets[4].data.push(row["5_percentage"]);
		}
		return datasets;
	};

	if (!chart.loaded) {
		return (
			<Fade in={!chart.loaded}>
				<Grid item {...rest} sx={{ mt: 0, p: 0 }}>
					<MDBox py={2} pr={2} pl={chart.display.icon.component ? 1 : 2}>
						<Skeleton variant="rounded" height={500} />
					</MDBox>
				</Grid>
			</Fade>
		);
	} else if (!data) {
		return (
			<Grid item {...rest}>
				<MDBox mb={1.5}>
					<LoadingComplexStatisticsCard
						icon={chart.display.icon}
						title={chart.display.title}
						text={data.rows?.length === 0 ? "Aucune donnée" : "Chargement des données"}
					/>
				</MDBox>
			</Grid>
		);
	} else {
		return (
			<Grid item {...rest}>
				<Card>
					<MDBox py={2} pr={2} pl={chart.display.icon?.component ? 1 : 2}>
						<MDBox
							display="flex"
							justifyContent="space-between"
							px={chart.display.description ? 1 : 0}
							pt={chart.display.description ? 1 : 0}
						>
							<MDBox display="flex">
								{chart.display.icon.component && (
									<MDBox
										width="4rem"
										height="4rem"
										bgColor={chart.display.icon.color || "info"}
										variant="gradient"
										coloredShadow={chart.display.icon.color || "info"}
										borderRadius="xl"
										display="flex"
										justifyContent="center"
										alignItems="center"
										color="white"
										mt={-5}
										mr={2}
									>
										<Icon fontSize="medium">{chart.display.icon.component}</Icon>
									</MDBox>
								)}
								<MDBox mt={chart.display.icon.component ? -2 : 0}>
									{chart.display.title && (
										<MDTypography variant="h6">{chart.display.title}</MDTypography>
									)}
									<MDBox mb={2}>
										<MDTypography component="div" variant="button" color="text">
											{chart.display.description}
										</MDTypography>
									</MDBox>
								</MDBox>
							</MDBox>
							{!editMode && (
								<MDBox mt={chart.display.icon.component ? -2 : 0}>
									<ListOptions chart={chart} options={chart.options ?? {}} />
								</MDBox>
							)}
						</MDBox>
						{/* Table */}
						<ComparisonReviewHeatmapTable
							table={data}
							canSearch={chart.display.subType !== "sortedList"}
						/>
						{/* Stacked chart */}
						<StackedChart
							noBody
							chart={{}}
							chartData={{
								labels: data.rows.map(r => r.name),
								datasets: convertDatasetToStackedChart(data.rows)
							}}
							options={{
								plugins: {
									legend: {
										reverse: true // Display legend in reverse order
									},
									tooltip: {
										callbacks: {
											// Custom tooltip
											label: tooltipItem => {
												const value = tooltipItem.raw;
												return `${tooltipItem.dataset.label}: ${value}%`;
											}
										}
									}
								},
								scales: {
									y: {
										// Y axis
										beginAtZero: true,
										min: 0,
										max: 100
									}
								}
							}}
						/>
					</MDBox>
				</Card>
			</Grid>
		);
	}
}

export default ComparisonReviewHeatmap;
